import React from "react";
import Recap from "./Recap";

const Posts = ({ posts, onPostClick }) => {
  return (
    <div>
     <table
          className="table-auto w-full border-separate    "
          style={{ borderSpacing: "0px 0px" }}
        >
          <thead className="text-[15px] text-[#0B0B0B] bg-[#F9F9F9] h-[43px] text-left ">
            <tr className="">
              <th className="w-[70%]  ">Élément de sécurité</th>
              <th className="w-[15%]">IA</th>
            </tr>
          </thead>
          <tbody className="">
            {
              posts? 
              posts.map((row,index)=>(
                <tr key={index} className="h-[43px]">
                <td className="w-[70%]">{row.problem}</td>
                <td className="w-[15%]"><Recap text="ke" checked={row.ai}></Recap></td>
              </tr>
              ))
              :""
            }
           
          </tbody>
      </table>
    </div>
  );
};

export default Posts;
