import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Pagination from "./Pagination";
import Posts from "./Posts";
import Recap from "./Recap";

export default function Report({ report = [] }) {
  const [pages, setpages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(7);
  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const currentPosts = report.slice(indexOfFirstPost, indexOfLastPost);
  const intervalPagination = 8;

  // Change page
  const paginateFront = () => setCurrentPage(currentPage + 1);
  const paginateBack = () => setCurrentPage(currentPage - 1);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="space-y-6">
      <style jsx="true">
        {`
          td,
          th {
            border-width: 1px 1px 1px 1px;
            border-style: solid;
            border-color: rgba(0, 0, 0, 0.12);
            padding-left: 5px;
          }
        `}
      </style>
      <h1 className="font-medium text-[28px]">
        Rapport d'intelligence artificielle
      </h1>
      <div className="px-4 flex h-[60vh] flex-col justify-between">
        <Posts posts={currentPosts} onPostClick={() => null} />
        <Pagination
          postsPerPage={postsPerPage}
          totalPosts={report.length}
          paginateBack={paginateBack}
          paginateFront={paginateFront}
          paginate={paginate}
          currentPage={currentPage}
          interval={intervalPagination}
        />
      </div>
    </div>
  );
}
