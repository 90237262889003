import React, { Component } from 'react'

class UnknownRoute extends Component {
  render () {
    return (
      <div className="w-full flex justify-center pt-10">
        "oups ! route inconnues"
      </div>
    )
  }
}

export default UnknownRoute