import { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import VideoPlayer from "./videoplayer";

export default function VideosContainer(props) {
  const [expanded, setExpanded] = useState(false);

  const [isSomeVideoPlaying, setIsSomeVideoPlaying] = useState(false);

  const [thumbnails, setThumbnails] = useState([]);
  const [zoomValue, setZoomValue] = useState(1);
  const [expandedVideoId, setExpandedVideoId] = useState(0);
  const { videos, step, checkStepData } = props;

  function expand(e, videoId) {
    e?.preventDefault();
    e?.stopPropagation();

    if (expandedVideoId !== videoId) {
      setExpandedVideoId(videoId);
    } else {
      setExpandedVideoId("");
    }
  }

  useEffect(() => {
    setExpandedVideoId(0);
  }, [step]);

  useEffect(() => {
      if(videos.length===1){
        expand(null, videos[0]?.id);
      }
  
  }, [videos]);

  useEffect(() => {}, [expandedVideoId]);

  return (
    <div
      className="fade relative videoContainer flex justify-center h-full items-center text-6xl  border-solid border-r  border-borderColor  "
      style={{ height: "92vh" }}
    >
         {/* {expandedVideoId === 0 ? (
        <div className="absolute z-50 inset-0 "></div>
      ) : (
        ""
      )} */}
    

      <div
        className={
          videos.length === 1 && expandedVideoId === 0
            ? "flex h-full justify-center items-center text-6xl p-16  space-x-2 relative w-1/2 "
            : videos.length === 1 && expandedVideoId !== 0
            ? "flex h-full justify-center items-center text-6xl    space-x-2 relative w-full "
            : videos.length > 1 && expandedVideoId === 0
            ? "flex w-full justify-center items-center text-6xl   p-12 space-x-2 relative w-full "
            : "flex h-full justify-center items-center text-6xl    space-x-2 relative w-full "
        }
      >
        {videos.length > 1 && !["",0].includes(expandedVideoId) ? (
          <div
            className={
              isSomeVideoPlaying
                ? "absolute flex   justify-evenly      z-20 "
                : "absolute flex  justify-evenly     z-20 "
            }
            style={{ top: "-55px", right : "0", width: "20%", height:"auto",  }}
          >
            {videos.map((video, index) => (
              <div
                onClick={(e) => setExpandedVideoId(video?.id)}
                className={
                  video?.id === expandedVideoId
                    ? "video_thumbnail relative cursor-pointer  w-[50px] flex justify-center items-center  border-solid border-4 rounded-full max-w-[50px]   h-[50px] overflow-hidden	  border-red-600 "
                    : "video_thumbnail relative cursor-pointer w-[50px] flex justify-center items-center rounded-full max-w-[50px] h-[50px] 	overflow-hidden"
                }
              >
                <img src={thumbnails?.find(x=>x.id===video?.id)?.image} className="w-full "
                style={{
                    width: "50px",
    height: "auto",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
                }}
                />
              </div>
            ))}
          </div>
        ) : (
          ""
        )}

        {videos.map((video, index) => (
          <VideoPlayer
           
            setThumbnails={setThumbnails}
            key={index}
            step={step}
            setIsSomeVideoPlaying={setIsSomeVideoPlaying}
            url={video?.url}
            checkStepData={checkStepData}
            videoId={video?.id}
            expandHandler={expand}
            expanded={video?.id === expandedVideoId}
            className={!["",0].includes(expandedVideoId) && expandedVideoId!==video?.id ? "invisible absolute" :""}
          />
        ))}
      </div>
    </div>
  );
}
