import React from "react";
import { Link } from "react-router-dom";
import logo from "../images/logo-blue.png"
export default function Layout({children}) {
  return (
    <div className=" ">
      <div className="max-h-[8vh] bg-white">
        {/* logo */}
        <div className="py-3 px-2 flex justify-between items-center ">
          <Link  to={"/"} className="cursor-pointer">
          <img
            src={logo}
            className="w-[120px] h-auto"
          />
          </Link>

  
        </div>
      </div> 
      <div>
        {children}
      </div>
    </div>
  );
}
