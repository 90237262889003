import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import BgHalfX from "../components/BgHalfX";
import Button from "../components/inputs/Button";
import Report from "../components/Report";
import VideosContainer from "../components/VideosContainer";
import { ai_report_exemple } from "../services/reports/ai_report";
import { operator_report_exemple } from "../services/reports/operator_report";
import { useParams } from "react-router-dom";
import Modal from "../components/Modal";
import UiLoading from "../components/UiLoading";
import Pagination from "../components/Pagination";
import api_handler from "../services/reports/apihandler";

export default function Home() {
  const [report, setReport] = useState(null);
  const [uiLoading, setUiLoading] = useState(true);
  const [videos, setVideos] = useState([]);
  const [countdownString, setCountDownString] = useState("");

  const [revisionCompleted, setRevisionCompleted] = useState(false);
  const [revisionPostponed, setRevisionPostponed] = useState(false);
  const paraams = useParams();
  const [docId, setDocId] = useState(paraams.id);
  const [errors, setErrors] = useState(null);

  async function getDocument(id) {
    try {
      const response = await api_handler.getDocument(id);
      const document = response?.payload?.data;
      console.log('====================================');
      console.log(document);
      console.log('====================================');
      if (document.authenticity) {
        let ai_report = document;
        let front_video = ai_report.document_front_video_url;
        let back_video = ai_report.document_back_video_url || null;
        const videosList = back_video ===  null ? [{ id: "shv-1", url: front_video }] : [{ id: "shv-1", url: front_video }, { id: "shv-2", url: back_video }]
        setVideos(videosList);
        const ai_rep_authenticities = document?.authenticity?.document.verif;
        const operator_rep_authenticities = document?.authenticity?.document.verif;
        let arr = [];
        for (let i = 0; i < ai_rep_authenticities.length; i++) {
          const ai_authenticity = ai_rep_authenticities[i];
    
          const operator_authenticity = operator_rep_authenticities.find(
            (x) => x.display_name === ai_authenticity.display_name
          );
          //find same authenticity in operator report
          if (operator_authenticity) {
            if (
              ai_authenticity.value === false ||
              operator_authenticity.value === false
            ) {
              let obj = {
                problem: operator_authenticity.display_name,
                officer: operator_authenticity.value,
                ai: ai_authenticity.value,
              };
              arr.push(obj);
            }
          }
        }
        
        setReport({ ...document, data: arr });
        
      }else { 
        setErrors(document.message)
      }
      } catch (e) {
      console.log(e);
      if (e && e?.response?.data?.description === "There is no document found") {
        setErrors("Document déjà étudié, toutes les données ont été supprimées")
      }
      else {
        setErrors("Nous avons pas le document correspondant.")
      }
    }
  }

  // function onCloseWindow() }
  
async  function onDelete() {
  try {
    const response = await api_handler.review(docId);
    if(response?.payload?.id) {

      onLeave();
    }
    } catch (e) {
      console.log(e);
      if (e && e?.response?.data?.description) {
        setErrors(e?.response?.data?.description)
      }
      else {
        setErrors("Nous rencontré une erreur")
      }
    }
  }

  async  function startReview() {
    try {
      const response = await api_handler.startReview(docId);
      if(response?.payload?.id) { }
      } catch (e) {
        console.log(e);
      }
    }
  
  function onLeave() { 
    /* Trying to close the window. */
    window.opener = null;
    window.open("about:blank", "_self");
    window.close();  
  }

  function countdown(minutes, seconds) {
    var endTime, hours, mins, msLeft, time;

    function twoDigits(n) {
      return n <= 9 ? "0" + n : n;
    }

    function updateTimer() {
      msLeft = endTime - +new Date();
      if (msLeft < 1000) {
        setCountDownString("Le temps pour la vérification est terminé!");
        //onLeave();
      } else {
        time = new Date(msLeft);
        hours = time.getUTCHours();
        mins = time.getUTCMinutes();
        let timeString =
          "" +
          (hours ? hours + ":" + twoDigits(mins) : mins) +
          ":" +
          twoDigits(time.getUTCSeconds());

        setCountDownString(timeString);
        setTimeout(updateTimer, time.getUTCMilliseconds() + 500);
      }
    }

    endTime = +new Date() + 1000 * (60 * minutes + seconds) + 500;
    updateTimer();
  }

  useEffect(() => {
    getDocument(docId);
    setUiLoading(false);
  }, [docId]);

  useEffect(() => {
    if (report && report.data) {      
      const REVIEW_DURATION = 2; // report.review_duration
      const SERVER_DATE_NOW =  report.server_time_now
      let STARTING_TIME;
      if (report.expert_start_review_at) {
        STARTING_TIME =  report.expert_start_review_at;
      } else {
        STARTING_TIME = SERVER_DATE_NOW;
        startReview();
      }
      const remainigTime = ( STARTING_TIME + REVIEW_DURATION*3600000) -  SERVER_DATE_NOW;
      const min = Math.floor((remainigTime/1000/60) << 0);
      const sec = Math.floor((remainigTime/1000) % 60);
      console.log(min, sec);
      countdown(min, sec);
    }
    return () => {};
  }, [report]);

  useEffect(() => {
    // const handleTabClose = event => {
    //   event.preventDefault();

    //   return (event.returnValue = 'Are you sure you want to exit?');
    // };

    // window.addEventListener('beforeunload', handleTabClose);

    // return () => {
    //   window.removeEventListener('beforeunload', handleTabClose);
    // };
  }, []);

  if (uiLoading) {
    return <UiLoading />;
  }
  
  return (
    <main style={{ minHeight: "92vh" }}>
      {errors ?  (
        <div className="w-full flex justify-center pt-10">
          {errors}
        </div>
      ):
      <div className="  mx-auto relative  h-full ">
        <div
          className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 border-solid border-t border-borderColor"
          style={{ height: "92vh" }}
        >
          <BgHalfX />

          {/* VIDEOS CONTAINER  */}
          {videos.length ? <VideosContainer step={1} videos={videos} /> : ""}

          <div className="flex flex-col bg-white  ">
            <div className="flex flex-col  justify-between h-full">
              <div className=" h-full ">
                <div
                  className=" bg-white  pt-6 px-7 overflow-y-scroll     "
                  style={{ maxHeight: "80vh" }}
                >
                  {report ? <Report report={report.data} /> : ""}
                </div>
               
              </div>
              <div>
                <div className="flex justify-center gap-3">
                  <span id="">
                    Temps restant avant suppression automatique:
                  </span>
                  <span id="countdown" className="font-bold">
                    {countdownString}
                  </span>
                </div>
                <div className="flex justify-center py-4 w-full space-x-5 ">
                  <Button
                    text={"Garder pour plus tard"}
                    onClick={() => setRevisionPostponed(true)}
                    color="white"
                  />

                  <Button
                    text="Supprimer"
                    onClick={() => setRevisionCompleted(true)}
                    color="blue"
                    disabled={false}
                  />
                </div>
              </div>
              <Modal show={revisionCompleted} setShow={setRevisionCompleted}>
                <div className="max-w-[490px] flex flex-col items-center text-center gap-6">
                  <h1 className="font-medium text-[28px]">Êtes-vous sûrs ?</h1>
                  <p>Toutes les données seront supprimées.</p>
                  <div className="flex justify-center py-4 w-full space-x-5 ">
                    <Button
                      text={"Précédent"}
                      onClick={() => {
                        setRevisionCompleted(false);
                      }}
                      color="white"
                    />
                    <Button
                      text="Supprimer"
                      onClick={() => {onDelete()}}
                      color="Terminer"
                    />
                  </div>
                </div>
              </Modal>
              <Modal show={revisionPostponed} setShow={setRevisionPostponed}>
                <div className="max-w-[490px] flex flex-col items-center text-center gap-6">
                  <h1 className="font-medium text-[28px]">Êtes-vous sûrs ?</h1>
                  <p>
                    Les données seront disponibles encore:{" "}
                    <span className="font-bold">{countdownString}</span>
                  </p>
                  <div className="flex justify-center py-4 w-full space-x-5 ">
                    <Button
                      text={"Précédent"}
                      onClick={() => {
                        setRevisionPostponed(false);
                      }}
                      color="white"
                    />
                    <Button
                      text="Quitter"
                      onClick={() => {  onLeave()}}
                      color="Terminer"
                    />
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
      }
    </main>
  );
}
