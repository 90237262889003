import React, { createContext, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import UnknownRoute from "./components/UnknownRoute"
 
function App() {

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path=":id" exact  element={<Layout><Home /></Layout>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
