import React, { Component } from 'react'

class UiLoading extends Component {
  render () {
    return (
      <div className="w-full flex justify-center pt-10">
        "Envoi en cours..."
      </div>
    )
  }
}

export default UiLoading