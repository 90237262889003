import {
  faArrowLeft,
  faCheck,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function Pagination({
  postsPerPage,
  totalPosts,
  paginate,
  currentPage,
  interval,
}) {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  const numberofpages=Math.ceil(totalPosts / postsPerPage)
  return (
    <div className="py-3 space-y-3">
      <div className="flex justify-end">
        <p className='text-sm text-black'>
                 
                    <span className='font-medium'>{currentPage+" "} 
                    </span>
                    sur
                    <span className='font-medium'> {numberofpages} </span>
                    Pages
                 
                </p>
        <FontAwesomeIcon
          icon={faChevronLeft}
          style={{ width: "20px", height: "20px", padding: "4px" }}
          className={`  rounded-md cursor-pointer ${currentPage-1!==0 ? "text-black": "text-gray-400"} `}
          onClick={()=>currentPage-1!==0 ?paginate(currentPage-1): null}
        />
        <FontAwesomeIcon
          icon={faChevronRight}
          style={{ width: "20px", height: "20px", padding: "4px" }}
          className={`text-black  rounded-md cursor-pointer ${currentPage+1!==numberofpages+1 ? "text-black": "text-gray-400"} `}
          onClick={()=>currentPage+1!==numberofpages+1 ? paginate(currentPage+1): null}
        />
      </div>
   
    </div>
  );
}
