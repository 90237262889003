
import { useEffect, useState } from 'react';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
export default function Recap(props) {

    useEffect(() => {

    }, [])

    return (

        <div className=" w-full  bg-white  rounded-lg">
            {
                props.right ?
                    <div className="w-full   ">

                        <div className="">{props.checked ?
                            <FontAwesomeIcon icon={faCheck} style={{width:"20px",height:"20px", padding:"4px" }}   className="bg-valide  text-white  rounded-md " />
                            :
                            <FontAwesomeIcon icon={faTimes} style={{width:"20px",height:"20px", padding:"2.5px" }}   className="bg-reject fa-fw text-white  rounded-md" />

                        }</div>
                        <div className="">  {props.text}</div>
                    </div>
                    :
                    <div className="w-full flex items-center justify-center ">
                        {/* <div>{props.text}</div> */}
                        <div className="">{props.checked ?
                            <FontAwesomeIcon icon={faCheck}  style={{width:"20px",height:"20px", padding:"4px"}} className="bg-valide  text-white  rounded-md " />
                            :
                            <FontAwesomeIcon icon={faTimes} style={{width:"20px",height:"20px", padding:"2.5px" }}  className="bg-reject fa-fw text-white  rounded-md" />

                        }</div>
                    </div>
            }

        </div>

    )
}


