import config from "../../config.json";
import axios from "axios";

const cfg = { baseURL: config.VAR_SHAREID_API_URL}

const getRequestHandler = () => {
  let service = axios.create(cfg);

  return service;
};

export const handler = {
  getDocument: async (id) => {
    return  await getRequestHandler()
      .get(`/v1/data/sdk/expert_document_authenticity_demand/${id}`)
      .then((res) => res.data);
  },
  startReview: async (id) => {
    return  await getRequestHandler()
      .post(`/v1/document_authenticity_demand/expert_start_document_authenticity_demand`, { id })
      .then((res) => res.data);
  },
  review: async (id) => {
    return  await getRequestHandler()
      .post(`/v1/document_authenticity_demand/expert_review_document_authenticity`, { id })
      .then((res) => res.data);
  },
};

export default handler;
