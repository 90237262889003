
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faTimes } from '@fortawesome/free-solid-svg-icons'


export default function Modal(props) {

  const { show, setShow } = props;

  const closeModal = (e) => {
    if(setShow)
    setShow(false)
  }

  useEffect(() => {


  }, [show])
  return (
    <div className="flex  flex-column items-center h-screen w-screen fixed bg-black bg-opacity-50 z-10 inset-0" style={{ visibility: (show ? "visible" : "hidden") }}>

      <div className="flex  justify-center items-center w-full max-h-screen  ">
        <div className="flex flex-col bg-white  h-full px-6 py-6  rounded-lg">
          <div className="flex justify-between items-center text-xl bg-white  ">
            <span></span>
            {
              !props.unclosable ?
              <FontAwesomeIcon icon={faTimes} onClick={(e) => closeModal(e)} className="text-right text-iconGrey hover:text-black cursor-pointer" />
              :
              ""
            }

          </div>

          <div className="px-10">
            {props.children}
          </div>

        </div>

      </div>

    </div>
  )
}


