import React, { useContext, useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPause,
  faPlay,
  faFastForward,
  faFastBackward,
  faStop,
  faSearchPlus,
  faCamera,
  faExpand,
  faCompress,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "./Modal";

// import axios from "axios";

//create your forceUpdate hook
function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update the state to force render
}

export default function VideoPlayer(props) {
  // call your hook here
  const forceUpdate = useForceUpdate();

  const [progress, setProgress] = useState(Number(0));
  const [play, setPlay] = useState(false);
  const [lockZoom, setLockZoom] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [videoReady, setVideoReady] = useState(false);

  const [backgroundWidth, setBackgroundWidth] = useState(1);
  const [zoomValue, setZoomValue] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const [canBePaused, setCanBePaused] = useState(false);

  const [zoomThumbnail, setZoomThumnnail] = useState("");

  const [snaped, setSnaped] = useState(false);

  const [videoWH, setVideoWH] = useState({
    width: "0px",
    height: "0px",
  });

  const { url, videoId, expandHandler, step, setThumbnails, className } = props;

  function captureThumbnail() {
    var video = document.getElementById(videoId);

    var canvas = document.querySelector("#canvas" + videoId);
    
    if (canvas) {
      var context = canvas.getContext("2d");
      var w, h, ratio;

      ratio = video.videoWidth / video.videoHeight;
      w = video.videoWidth;
  
      h = parseInt(w / ratio, 10);
       setBackgroundWidth(video.videoWidth);
      canvas.width = w; 
      canvas.height = h;
      

      context.fillRect(0, 0, w, h);
      context.drawImage(video, 0, 0, w, h);

      //setSnaped(true);
      var img = canvas.toDataURL("image/png");

      setThumbnails((prev) => [...prev, { id: videoId, image: img }]);
    }
  }

  function captureZoomThumbnail() {
    var video = document.getElementById(videoId);

    var canvas = document.querySelector("#canvas" + videoId);

    if (canvas) {
      var context = canvas.getContext("2d");
      var w, h, ratio;

      ratio = video.videoWidth / video.videoHeight;
      w = video.videoWidth;
      h = parseInt(w / ratio, 10);

      canvas.width = w;

      canvas.height = h;

      context.fillRect(0, 0, w, h);
      context.drawImage(video, 0, 0, w, h);

      //setSnaped(true);
      var img = canvas.toDataURL("image/png");

      setZoomThumnnail(img);
    }
  }

  function playPauseVideo(e) {
    if (!play) {
      document.getElementById(videoId).play();
      setPlay(true);
    } else {
      captureZoomThumbnail();
      document.getElementById(videoId).pause();
      setPlay(false);
    }
  }

  function onProgressClick(e) {
    e.preventDefault();
    e.stopPropagation();
    let p = document.getElementById("prog" + videoId);

    let video = document.getElementById(videoId);

    var totalWidth = p.getBoundingClientRect().width;

    let offsetLeft = p.getBoundingClientRect().left;

    var widthclicked = e.pageX - offsetLeft;

    var calc = (widthclicked * video.duration) / totalWidth;

    video.currentTime = calc.toFixed(2);
  }

  function stopVideo(e) {
    document.getElementById(videoId).pause();
    document.getElementById(videoId).currentTime = 0;
    setPlay(false);
  }

  function move(b) {
    let video = document.getElementById(videoId);
    let time = 0.5;
    if (b) {
      if (video.currentTime + time > video.duration)
        video.currentTime = video.duration;
      else video.currentTime = video.currentTime + time;
    } else {
      if (video.currentTime - time > 0)
        video.currentTime = video.currentTime - time;
      else video.currentTime = 0;
    }
  }

  function changeZoomValue(e) {
    e.preventDefault();
    e.stopPropagation();
    if (zoomValue === 1) setZoomValue(1.5);
    if (zoomValue === 1.5) setZoomValue(2);
    if (zoomValue === 2) setZoomValue(2.5);
    if (zoomValue === 2.5) setZoomValue(3);
    if (zoomValue === 3) setZoomValue(3.5);
    if (zoomValue === 3.5) setZoomValue(4);
    if (zoomValue === 4) setZoomValue(5);
    if (zoomValue === 5) setZoomValue(1);
  }
  useEffect( () => {
    setVideoReady(false);
    let video = document.getElementById(videoId);

    // let res= await fetch("/api/onboarding/video/"+url.iv+"/"+url.content, {headers: { Authorization: "Bearer "}});

    // const blob = await res.blob();

    // if (blob) {
    //   video.src = URL.createObjectURL(blob);

    //   // Load the new resource
    //   video.load();

    //   console.info("Video Ready!");
    // } else {
    //   console.warn("Can not load");
    // }

    if (video !== null) {
      // video.src={"/api/onboarding/video/"+url.iv+"/"+url.content}

      video.src = url;
      if (video.readyState === 4) {
        video.pause();
        video.currentTime = 0;
        setDuration(video.duration.toFixed(2));

        setVideoWH({
          width: video.videoWidth,
          height: video.videoHeight,
        });
      }

      video.onloadeddata = function () {
        setDuration(this.duration.toFixed(2));
        setVideoReady(true);
      };

      var b = setInterval(() => {
        if (video.readyState === 4) {
          captureZoomThumbnail();
          clearInterval(b);
        }
      }, 500);

      video.ontimeupdate = function (e) {
        setCurrentTime(this.currentTime.toFixed(2));
        setProgress((this.currentTime * 100) / this.duration);
        setCanBePaused(true);

        captureZoomThumbnail();
      };

      video.onpause = function (e) {
        setPlay(false);
      };
    }

    // expandHandler(null, videoId)
  }, [videoId]);

  useEffect(() => {
    setZoomValue(1);
    setLockZoom(false);
    //stop videos playing when we go to the next or previous step
    let video = document.getElementById(videoId);

    setPlay(false);
    if (video !== null) {
      if (video.readyState >= 4) {
        video.pause();
        video.currentTime = 0;
        captureZoomThumbnail();
        setDuration(video.duration.toFixed(2));

        setVideoWH({
          width: video.videoWidth,
          height: video.videoHeight,
        });
      }

      // video.ontimeupdate = function (e) {
      //   setCurrentTime(this.currentTime.toFixed(2));
      //   setProgress((this.currentTime * 100) / this.duration);
      //   setCanBePaused(true);
      // };
    }
  }, [step]);

  useEffect(() => {}, [backgroundWidth]);

  useEffect(() => {
    let video = document.getElementById(videoId);

    var b = setInterval(() => {
      if (video.readyState >= 3) {
        captureThumbnail();

        clearInterval(b);
      }
    }, 500);
  }, [props.expanded]);

  function zoom(e) {
    //  console.log(e)

    let offsetX = 0,
      offsetY = 0;
    var zoomer = document.getElementById("zoom-area" + videoId);

    let position = getMousePosition(e);

    // console.log(position)

    let x = (position.posX * 100) / zoomer.offsetWidth;

    let y = (position.posY * 100) / zoomer.offsetHeight;
    let pos = "" + x + "% " + y + "%";
    // console.log(pos)
    zoomer.style.backgroundPosition = "" + x + "% " + y + "%";
  }

  function onVideoAreaClick(isVideoPlaying) {
    if (isVideoPlaying) {
      playPauseVideo();
      setLockZoom(false);
    } else {
      setLockZoom(!lockZoom);
    }
  }

  function getMousePosition(e) {
    let bounds = e.target.parentElement.getBoundingClientRect();
    let x = e.clientX - bounds.left;
    let y = e.clientY - bounds.top;

    // // mouse position minus elm position is mouseposition relative to element:
    // dbg.innerHTML = ' X Position: ' + (m_posx-e_posx)
    //               + ' Y Position: ' + (m_posy-e_posy);

    return {
      posX: x,
      posY: y,
    };
  }

  return (
    <div
      className={
        !videoReady
          ? `  ${className} w-full sh-box-shadow  fade  flex justify-center items-center relative bg-black opacity-50`
          : ` ${className} fade overflow-hidden max-w-[360px]  sh-box-shadow h-full flex justify-center items-center  `
      }
    >
      <style jsx={"true"}>
        {`
          .thumbnailbg {
            -webkit-background-size: ${backgroundWidth * zoomValue}px auto;
            -moz-background-size: ${backgroundWidth * zoomValue}px auto;
            -o-background-size: ${backgroundWidth * zoomValue}px auto;
            background-size: ${backgroundWidth * zoomValue}px auto;
          }

          .thumbnailbg${lockZoom ? "" : ":hover"} > video {
            opacity: ${lockZoom ? "0" : "0"};
          }

          .thumbnailbg:hover > video {
            transition: opacity 0.5s;
            display: block;

            width: 100%;
          }
        `}
      </style>
      {!videoReady ? (
        <div className=" absolute inset-0 z-20    ">
          <div className="flex justify-center items-center h-full bg-black">
            <p className="loader"></p>
          </div>
        </div>
      ) : (
        ""
      )}
      {/* <div className="video-area" style={{width: videoWH.width, height: videoWH.height }}> */}
      <div
        className={
          props.expanded
            ? "video-area   absolute inset-0  z-10 w-full  cursor-pointer rounded-lg  lg:text-5xl md:text-6xl sm:text-4xl text-3xl bg-white"
            : " video-area   relative w-full  cursor-pointer rounded-lg  lg:text-5xl md:text-6xl sm:text-4xl text-3xl "
        }
        onClick={(e) =>
          play ? onVideoAreaClick(true) : onVideoAreaClick(false)
        }
      >
        {/* <video muted   id={videoId}   src={"https://www.w3schools.com/tags/mov_bbb.mp4"} className='sh-video' onmousemove="zoom(event)" > </video> */}
        <div
          id={"zoom-area" + videoId}
          className={!play ? " thumbnailbg w-full h-full " : "w-full h-full"}
          style={
            !play
              ? {
                  backgroundImage: "url(" + zoomThumbnail + ")",
                  backgroundRepeat: "no-repeat",
                  position: "relative",
                  overflow: "hidden",
                  cursor: "zoom-in",
                }
              : {}
          }
          onMouseMove={(e) => (!lockZoom ? zoom(e) : null)}
        >
          <video
            muted
            id={videoId}
            crossOrigin="anonymous"
            className={
              props.expanded
                ? "  sh-video   bg-black h-full "
                : "sh-video rounded-lg  h-full transition duration-500 "
            }
          >
            {" "}
          </video>
        </div>
        {!play && videoReady ? (
          <FontAwesomeIcon
            icon={!play ? faPlay : faPause}
            onClick={(e) => playPauseVideo(e)}
            className=" ml-2  text-white  absolute  top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-50"
          />
        ) : (
          ""
        )}
        {!videoReady ? (
          ""
        ) : (
          <div className="sh-videoplayer ">
            <div className="progress-area">
              <div
                id={"prog" + videoId}
                className="progressbar"
                onClick={(e) => onProgressClick(e)}
              >
                <div
                  style={{ width: "" + progress + "%" }}
                  className="progress"
                ></div>{" "}
              </div>
              {/* <div className="vidinfo">

       <div>{duration} s</div>
     </div> */}
            </div>
            <div
              className="video_operations flex  text-lg justify-between w-full items-center  "
              style={{ height: "35px" }}
            >
              <div className="flex h-full text-center items-center">
                <div
                  className="w-8 hover:shadow-lg mr-1"
                  onClick={(e) => playPauseVideo(e)}
                >
                  <FontAwesomeIcon
                    icon={!play ? faPlay : faPause}
                    className="video_control  "
                  />
                </div>

                {/* {capturable===true  ? (
                  <div className="w-8">
                    <FontAwesomeIcon
                      icon={faCamera}
                      onClick={(e) => capture(e)}
                      className="video_control"
                    />
                  </div>
                ) : (
                  ""
                )} */}

                <div className="text-sm"><span>{currentTime}</span><span>{" "}s</span></div>
              </div>
              <div className=" flex justift-center items-center pr-3  ">
                <div
                  className="flex text-sm  justift-center items-center "
                  onClick={(e) => changeZoomValue(e)}
                >
                  <FontAwesomeIcon
                    icon={faSearchPlus}
                    className="video_control w-4 h-4"
                  />
                  {"x " + zoomValue}
                </div>
                <div className="w-8">
                  <FontAwesomeIcon
                    icon={!props.expanded ? faExpand : faCompress}
                    onClick={(e) => expandHandler(e, videoId)}
                    className="video_control"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <Modal show={snaped} setShow={setSnaped}>
        {" "}
        <canvas id={"canvas" + videoId} style={{ maxHeight: "91vh" }}></canvas>
      </Modal>
    </div>
  );
}
